// keep for future open positions

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="Senior DevOps Engineer - Software Careers | MCRO"
    description={`Devops Engineer at MCRO: if you are a forward thinking, problem solver with a strong passion for 
    DevOps and Infrastructure at MCRO you will collaborate with an extremely talented and professional team of 
    engineers that will take your career towards success and will help you grow and deliver the best quality for top 
    clients and innovative products.`}
    metaContent="devops, google cloud services, amazon web services, elastic search, cloud formation, deployments"
    id="devops"
    jobTitle="DevOps Engineer"
    jobDescription={`If you are a forward thinking, problem solver with a strong passion for DevOps
    and Infrastructure at MCRO you will collaborate with an extremely talented and professional team of
    engineers that will boost your career towards success and will help you grow and deliver the best
    quality for top clients and innovative products.`}
    mailHref="mailto:jobs@mcro.tech?subject=DevOps Job Application"
    roles={[
      'Develop new infrastructure or maintain an existing',
      'Sync up on a daily basis with a distributed team and respect the coding standards required',
      'Deliver in a controlled agile environment',
      'Responsible for automated deployments and system security'
    ]}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj-Napoca Office',
      'Private healthcare insurance for employees'
    ]}
    requirements={[
      'Great communication skills verbal and written English',
      'Strong experience with Linux and configuration management systems',
      'Strong Experience packaging applications with Docker and deploying applications to Kubernetes clusters',
      `Strong Experience deploying applications with Chef, Puppet, Ansible,
    Terraform, Cloud Formation or similar systems`,
      'Strong experience with AWS',
      'Strong experience with Databases SQL and no-sql',
      'Strong experience automated and continuous deployments and integrations',
      'Jenkins, Bamboo, CircleCI or comparable continuous integration systems',
      'Experience in detecting system security problems and be aware of them',
      'Previous experience with Apache Server Configs',
      'Previous experience with Node Server configs',
      'Previous experience with Elastic Search',
      'Previous experience with Kafka or other message queue system is a plus',
      'Previous experience with Google Clould Services is a plus',
      'Experience with NodeJS is a plus *',
      'Experience in product management and analysis is a plus'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
  query devopsCareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
